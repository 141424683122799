import React, { useContext } from "react";
import { IoSearch } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router";

import { UserStates } from "../../context/UserContext";

const SearchBar = ({ setSearchWord }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  console.log("pathname", pathname);

  const { search, setSearch } = useContext(UserStates);

  const handleNavigate = (e) => {
    e.preventDefault();
    if (pathname === "/warehouse") {
      console.log("");
    } else if (search && search.trim().length > 0) {
      navigate("/marketplace");
    }
  };

  return (
    <div className="w-full px-5">
      <form className="max-full mx-auto">
        <label
          form="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only :text-white"
        >
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
          <input
            type="search"
            onChange={(e) => {
              if (pathname === "/marketplace" && e.target.value === "") {
                navigate("/marketplace");
              }
              setSearch(e.target.value);
              if (search !== undefined) setSearch(e.target.value);
            }}
            id="default-search"
            className="block w-full p-4 md:ps-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 :bg-gray-700 :border-gray-600 :placeholder-gray-400 :text-white :focus:ring-blue-500 :focus:border-blue-500"
            placeholder="Search by category, brand or name"
            required
            value={search}
          />
          <button
            onClick={handleNavigate}
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-[#013220] hover:bg-[#00BF63] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 :bg-blue-600 :hover:bg-blue-700 :focus:ring-blue-800"
          >
            <IoSearch className="text-lg" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
