import React, { useContext, useState } from "react";
import { Navbar, Typography, Button } from "@material-tailwind/react";
import { SunIcon } from "@heroicons/react/16/solid";
import { ShoppingBagOutlined } from "@mui/icons-material";
import { MdOutlineShoppingBag } from "react-icons/md";
import { FaRegBell } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import styles from "./home.module.css";
import logo from "../../assets/top-bar-logo.png";
import SearchBar from "../UI/SearchBar";
import { UserStates } from "../../context/UserContext";
import EditUserModal from "./editUserModal";

export function NavbarDefault() {
  const navigate = useNavigate();

  const { temperature } = useContext(UserStates);

  const [showModal, setShowModal] = useState(false);

  const navList = (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 text-black w-full">
      <SearchBar />
    </ul>
  );

  return (
    <>
      <Navbar className="mx-auto px-4 py-2 lg:px-8 lg:py-4 border-0 shadow-sm top-0 z-50 w-screen navbar">
        <div className="container mx-auto flex items-center justify-between text-black">
          <Typography
            as="a"
            href="#"
            className="mr-4 cursor-pointer py-1.5 font-medium"
          >
            <span className="flex gap-1">
              <img
                className="w-10 lg:w-16"
                src={logo}
                alt="Gefmart logo"
                onClick={() => navigate("/")}
              />
              <p className=" lg:hidden text-sm">Hi, User</p>
            </span>
          </Typography>
          <div className={`${styles.navbarContainer} hidden lg:block`}>
            {navList}
          </div>
          <div className={`${styles.nameContainer} flex items-center gap-x-1`}>
            <div className="hidden lg:flex">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowModal(true)}
              >
                <p
                  className="font-medium"
                  style={{ marginRight: "10px", width: "165px" }}
                >
                  {localStorage.getItem("user-name")}{" "}
                </p>
                <img
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "25px",
                    objectFit: "cover",
                  }}
                  src={localStorage.getItem("profile-pic")}
                />
              </div>
            </div>
            <div className="relative">
              <Button
                variant="text"
                size="sm"
                className=" lg:inline-block"
                onClick={() => navigate("/cart")}
              >
                <MdOutlineShoppingBag className="text-2xl" />
              </Button>
            </div>
            {/* <div className="mt-1">
              <span className="relative inline-block ">
                <FaRegBell className="text-xl" />
                <span className="absolute top-1 right-1 inline-block w-2 h-2 transform translate-x-1/2 -translate-y-1/2 bg-[#00BF63] rounded-full"></span>
              </span>
            </div> */}
          </div>
        </div>
      </Navbar>
      <div className={styles.mobileNavbar}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ width: "50px", height: "50px" }}
            className="w-10 lg:w-16"
            src={logo}
            alt="Gefmart logo"
            onClick={() => navigate("/")}
          />
          <div style={{ marginLeft: "20px" }}>
            <h4>Hi, {localStorage.getItem("user-name")}</h4>
            {temperature !== null && (
              <h4
                style={{
                  color: "#C5BE1C",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SunIcon style={{ width: "20px", marginRight: "5px" }} />
                {temperature.temperature}°C {temperature.weatherStatus}
              </h4>
            )}
          </div>
        </div>
        <ShoppingBagOutlined
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => navigate("/cart")}
        />
      </div>

      <EditUserModal show={showModal} setShow={setShowModal} />
    </>
  );
}
