import axios from "axios";
import { Button, Select, Switch, Upload, message } from "antd";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./marketplace.module.css";
import BackIcon from "../../Components/BackIcon";
import { UserStates } from "../../context/UserContext";
import {
  getAllBrandsUrl,
  getAllCategoriesUrl,
  getSingleMarketplaceUrl,
  getUserDetailsUrl,
  subCategoriesUrl,
} from "../../apis/constants";

const AddToMarketPlacePage = () => {
  const { userId } = useContext(UserStates);

  const accessToken = Cookies.get("gefmartUserToken");

  const params = useParams();
  const navigate = useNavigate();

  const [brands, setBrands] = useState([]);
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [hidePrice, setHidePrice] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [product, setProduct] = useState({
    images: [],
    retail_price: 0,
    wholesale_price: 0,
    quantity: 0,
    name: "",
    description: "",
  });

  // get categories
  const getCategories = () => {
    const config = {
      method: "post",
      url: getAllCategoriesUrl,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setCategories(res.data.data))
      .catch((err) => console.error("error in getCategories", err));
  };

  // get sub categories
  const getSubCategories = () => {
    const config = {
      method: "get",
      url: `${subCategoriesUrl}${selectedCategory}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setSubCategories(res.data.data))
      .catch((err) => console.error("error in getCategories", err));
  };

  // get brands
  const getBrands = () => {
    const config = {
      method: "post",
      url: getAllBrandsUrl,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setBrands(res.data.data))
      .catch((err) => console.error("error in getCategories", err));
  };

  // get user address
  const getUserAddress = () => {
    const config = {
      method: "get",
      url: `${getUserDetailsUrl}${userId}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        let address = res.data.data.address;

        setAddress({
          address,
          latitude: res.data.data.latitude,
          longitude: res.data.data.longitude,
        });
      })
      .catch((err) => err);
  };

  // create or update marketplace product
  const createMarketplaceListing = () => {
    setLoading(true);
    const formData = new FormData();

    // Loop through each key in the product object and append accordingly
    Object.keys(product).forEach((key) => {
      const value = product[key];

      if (key === "images" && Array.isArray(value)) {
        // Append each image file individually
        value.forEach((item) => {
          if (item.originFileObj) {
            formData.append("files", item.originFileObj);
          }
        });
      } else if (value !== undefined && value !== null) {
        console.log("value when looping object", key, ":", value);
        // console.log("")
        // Append non-array fields directly, including empty strings or zero
        formData.append(key, value);
      }
    });

    // Append address details if params.id is not provided
    // if (params.id === undefined) {
    formData.append("latitude", address.latitude);
    formData.append("longitude", address.longitude);
    formData.append("address[district]", address.address.district);
    formData.append("address[pincode]", address.address.pincode);
    formData.append("address[state]", address.address.state);
    formData.append("address[landmark]", address.address.landmark);
    formData.append("address[city]", address.address.city);
    // }

    // Manually append any additional fields outside of the product object
    formData.append("userId", userId);
    formData.append("brandId", selectedBrand);
    formData.append("categoryId", selectedCategory);
    formData.append("subCategoryId", selectedSubcategory);
    formData.append("hidden_price", hidePrice);

    const config = {
      method: params.id !== undefined ? "patch" : "post",
      url:
        params.id !== undefined
          ? `${getSingleMarketplaceUrl}${params.id}`
          : getSingleMarketplaceUrl,
      headers: { Authorization: accessToken },
      data: formData,
    };

    // axios(config)
    //   .then((res) => {
    //     navigate("/marketplace");
    //     message.success(res.data.message);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     message.error(err?.message);
    //     console.error(err);
    // setLoading(false);
    //   });
  };

  useEffect(() => {
    getCategories();
    getBrands();
    getUserAddress();
  }, []);

  useEffect(() => {
    if (selectedCategory !== "" && selectedCategory !== undefined) {
      getSubCategories();
    }
  }, [selectedCategory]);

  const handleUploadChange = ({ fileList }) =>
    setProduct({ ...product, images: fileList });

  const getMarketPlaceItems = () => {
    const config = {
      method: "get",
      url: `${getSingleMarketplaceUrl}${params.id}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        // setProduct(res.data.data);
        setHidePrice(res.data.data.hidden_price);
        setSelectedBrand(res.data.data.brand._id);
        setSelectedCategory(res.data.data.category._id);
        setSelectedSubcategory(res.data.data.sub_category._id);

        const formattedFileList = res.data.data.images.map((image, index) => ({
          uid: `${index}`, // Use a unique id for each image
          name: `Image-${index + 1}`, // Name can be dynamic or fixed
          status: "done", // Mark as successfully uploaded
          url: image, // URL from the database
        }));
        setProduct({ ...res.data.data, images: formattedFileList });
      })
      .catch((err) => {
        // setMarketplaceListings([]);
      });
  };

  useEffect(() => {
    getMarketPlaceItems();
  }, [params.id]);

  return (
    <>
      <BackIcon
        page={"Add to Marketplace"}
        marginTop={"10px"}
        marginLeft={"10px"}
      />

      <div className={styles.container}>
        <div className={styles.itemContainer}>
          <span>Product Name *</span>
          <input
            type="text"
            name="name"
            value={product?.name}
            onChange={(e) => setProduct({ ...product, name: e.target.value })}
          />
        </div>
        <div className={styles.itemContainer}>
          <span>Category *</span>
          <Select
            fullWidth
            onChange={(e) => setSelectedCategory(e)}
            value={selectedCategory}
            className="product"
            showSearch
            optionFilterProp="children"
          >
            {categories?.map((category) => (
              <Select.Option value={category?._id}>
                {category?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.itemContainer}>
          <span>Sub Category *</span>
          <Select
            fullWidth
            onChange={(e) => setSelectedSubcategory(e)}
            value={selectedSubcategory}
            className="product"
            showSearch
            optionFilterProp="children"
          >
            {subCategories?.map((category) => (
              <Select.Option value={category?._id}>
                {category?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.itemContainer}>
          <span>Brand *</span>
          <Select
            fullWidth
            onChange={(e) => setSelectedBrand(e)}
            value={selectedBrand}
            className="product"
            showSearch
            optionFilterProp="children"
          >
            {brands?.map((brand) => (
              <Select.Option value={brand?._id}>{brand?.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.twoItemsContainer}>
          <div className={styles.itemContainer} style={{ width: "49%" }}>
            <span>Wholesale Price *</span>
            <input
              type="number"
              name="wholesale_price"
              // value={product?.wholesalePrice}
              value={
                Number(product?.wholesale_price) === 0
                  ? "Wholesale Price"
                  : product?.wholesale_price
              }
              placeholder="Wholesale Price"
              onChange={(e) =>
                setProduct({ ...product, wholesale_price: e.target.value })
              }
            />
          </div>
          <div className={styles.itemContainer} style={{ width: "49%" }}>
            <div className={styles.hidePriceContainer}>
              <span>Hide Price</span>
              <Switch
                title="Hide Price"
                onChange={() => setHidePrice(!hidePrice)}
                value={hidePrice}
              />
            </div>
            <input
              type="number"
              name="retail_price"
              placeholder="Retail Price"
              value={
                Number(product?.retail_price) === 0
                  ? "Retail Price"
                  : product?.retail_price
              }
              onChange={(e) =>
                setProduct({ ...product, retail_price: e.target.value })
              }
            />
          </div>
        </div>

        <div className={styles.itemContainer}>
          <span>Quantity</span>
          <input
            type="number"
            name="quntity"
            value={product?.quantity}
            onChange={(e) =>
              setProduct({ ...product, quantity: e.target.value })
            }
          />
        </div>

        <div className={styles.itemContainer}>
          <span>Tags</span>
          <input
            type="text"
            name="tags"
            value={product?.tags}
            onChange={(e) => setProduct({ ...product, tags: e.target.value })}
          />
        </div>

        <div className={styles.itemContainer}>
          <span>Description</span>
          <textarea
            style={{ width: "100%", height: "100px" }}
            type="text"
            name="description"
            value={product?.description}
            onChange={(e) =>
              setProduct({ ...product, description: e.target.value })
            }
          />
        </div>

        <div className={styles.itemContainer}>
          <span>Product Image *</span>
          <Upload
            multiple
            listType="picture"
            fileList={product?.images}
            onChange={handleUploadChange}
            beforeUpload={() => false} // Prevents automatic upload
          >
            <Button className={styles.uploadBtn}>Add Image</Button>
          </Upload>
        </div>

        <div className={styles.itemContainer}>
          <button onClick={createMarketplaceListing} disabled={loading}>
            {params?.id ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddToMarketPlacePage;
