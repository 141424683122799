import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
import { baseUrl } from "../apis/constants";
import axios from "axios";

export const UserStates = createContext();

export default function UserContext({ children }) {
  const userId = localStorage.getItem("userId");

  const [cart, setCart] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [temperature, setTemperature] = useState(null);
  const [currentLocation, setCurrentLocation] = useState({});
  const [accessToken, setAccessToken] = useState(
    Cookies.get("gefmartUserToken")
  );

  // Function to map weather codes to descriptions
  const getWeatherDescription = (code) => {
    const weatherDescriptions = {
      0: "Clear sky",
      1: "Mainly clear",
      2: "Partly cloudy",
      3: "Overcast",
      45: "Fog",
      48: "Depositing rime fog",
      51: "Light drizzle",
      53: "Moderate drizzle",
      55: "Dense drizzle",
      56: "Freezing light drizzle",
      57: "Freezing dense drizzle",
      61: "Slight rain",
      63: "Moderate rain",
      65: "Heavy rain",
      66: "Light freezing rain",
      67: "Heavy freezing rain",
      71: "Slight snowfall",
      73: "Moderate snowfall",
      75: "Heavy snowfall",
      80: "Slight rain showers",
      81: "Moderate rain showers",
      82: "Violent rain showers",
      85: "Slight snow showers",
      86: "Heavy snow showers",
    };

    return weatherDescriptions[code] || "Unknown weather";
  };

  const getWeather = (lat, lon) => {
    const url = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lon}&current_weather=true`;

    axios
      .get(url)
      .then((response) => {
        const { temperature, weathercode } = response.data.current_weather;

        // Use weather code to map to status (e.g., sunny, cloudy, etc.)
        const weatherStatus = getWeatherDescription(weathercode);

        setTemperature({ temperature, weatherStatus });
        // setWeatherStatus(weatherStatus);

        // const temp = response.data.current_weather.temperature; // Get temperature in Celsius
        // setTemperature(temp);
      })
      .catch((error) => console.log("Error fetching weather data", error));
  };

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          getWeather(position.coords.latitude, position.coords.longitude);
        },
        (error) => setCurrentLocation({})
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getCart = () => {
    const config = {
      method: "get",
      url: `${baseUrl}carts/${userId}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setCart(res.data.data))
      .catch((err) => setCart([]));
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  return (
    <UserStates.Provider
      value={{
        accessToken,
        currentLocation,
        userId,
        cart,
        setCart,
        getCart,
        setAccessToken,
        temperature,
        setTemperature,
        currentPage,
        setCurrentPage,
        search,
        setSearch,
      }}
    >
      {children}
    </UserStates.Provider>
  );
}
