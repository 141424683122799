// import React from "react";

// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Autoplay } from "swiper/modules";

// const SliderComponent = ({ slides }) => {
//   return (
//     <Swiper
//       modules={[Navigation, Pagination, Autoplay]}
//       spaceBetween={20} // space between slides
//       slidesPerView={1} // mobile view
//       loop={true} // infinite scroll
//       pagination={{ clickable: true }} // pagination for easier navigation
//       navigation={true} // navigation buttons
//       breakpoints={{
//         768: {
//           slidesPerView: 1.5, // show one and a half slides on tablets
//         },
//         1024: {
//           slidesPerView: 2.5, // show two and a half slides on desktop
//         },
//       }}
//       style={{ width: "100%", height: "400px" }}
//       autoplay={{ delay: 3000 }}
//     >
//       {slides.map((slide, index) => (
//         <SwiperSlide key={index}>
//           <div
//             style={{
//               width: "100%",
//               height: "100%",
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               alignItems: "center",
//               backgroundColor: "#ddd",
//               borderRadius: "8px",
//               overflow: "hidden",
//             }}
//           >
//             <img
//               src={slide.image}
//               alt={slide.name}
//               style={{ width: "100%", height: "100%", objectFit: "cover" }}
//             />
//             {/* <p style={{ margin: "10px 0", fontWeight: "bold" }}>{slide.name}</p> */}
//           </div>
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   );
// };

// export default SliderComponent;

// const onAutoplayTimeLeft = (s, time, progress) => {
//   progressCircle.current.style.setProperty("--progress", 1 - progress);
//   progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
// };

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { FreeMode, Pagination, Autoplay, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";

export default function App({ slides }) {
  const navigate = useNavigate();

  return (
    <Swiper
      slidesPerView={3}
      freeMode={true}
      modules={[Autoplay, Pagination, Navigation, FreeMode]}
      // onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="mySwiper"
      spaceBetween={20} // space between slides
      loop={true} // infinite scroll
      pagination={{ clickable: true }} // pagination for easier navigation
      navigation={true} // navigation buttons
      breakpoints={{
        768: {
          slidesPerView: 1.5, // show one and a half slides on tablets
        },
        1024: {
          slidesPerView: 2.5, // show two and a half slides on desktop
        },
      }}
      style={{ width: "123%", height: "50vh", marginLeft: "-23%" }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ddd",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <img
              src={slide.image}
              alt={slide.name}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/warehouse/${slide?.products?._id}`)}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
