import React from "react";

const PriceBar = ({ total }) => {
  // const deliveryCharge = 20;
  return (
    <div className="p-5 bg-white p-2 w-full flex flex-col gap-1 cartBottomItems">
      <h1 className=" font-medium">Price Details</h1>
      <div className="flex justify-between">
        <p>Items Total</p>
        <p>₹{total}</p>
      </div>
      {/* <div className="flex justify-between">
        <p>Delivery</p>
        <p>₹{deliveryCharge}</p>
      </div> */}
      <div className="flex justify-between border-y-2 border-dotted p-1">
        <p className="font-medium">Total</p>
        <p className=" text-[#00BF63]">₹{total}</p>
      </div>
    </div>
  );
};

export default PriceBar;
