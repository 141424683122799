import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function BackIcon({ page, marginTop, marginLeft }) {
  const navigate = useNavigate();

  return (
    <div
      style={{ marginTop, marginLeft }}
      onClick={() => navigate(-1)}
      className="backIconCustom"
    >
      <FaArrowLeft className=" text-lg" style={{ marginRight: "6px" }} />
      <h5>{page}</h5>
    </div>
  );
}
