import React, { useContext, useEffect, useState } from "react";
import TimeLine from "./TimeLine";
import { UserStates } from "../../context/UserContext";
import { changeOrderStatusUrl, ordersUrl } from "../../apis/constants";

import axios from "axios";
import styles from "./cart.module.css";
import dummyPdtImg from "../../assets/dummyPdtImg.jpeg";
import { message } from "antd";
import dayjs from "dayjs";

const MyOrders = () => {
  const { accessToken, userId } = useContext(UserStates);

  const [show, setShow] = useState();
  const [orders, setOrders] = useState();
  const [selectedIndex, setSelectedIndex] = useState();

  const getOrdersHistory = () => {
    const config = {
      method: "post",
      url: `${ordersUrl}${userId}`,
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => setOrders(res.data.data))
      .catch((err) => err);
  };

  const cancelOrder = (id) => {
    const config = {
      method: "patch",
      url: `${changeOrderStatusUrl}${id}`,
      headers: { Authorization: accessToken },
      data: {
        date: new Date(),
        status: "Cancelled",
        userId,
        // deliveredBy: "65e219569995a1079cf7e1a0",
      },
    };

    axios(config)
      .then((res) => {
        window.location.reload();
        message.success("Order cancelled successfully");
      })
      .catch((err) => err);
  };

  useEffect(() => {
    getOrdersHistory();
  }, []);

  return (
    <div className="bg-[#F5F5F5]" style={{ marginBottom: "60px" }}>
      {orders?.map((order, index) => (
        <div className={styles.container}>
          <div className={styles.image}>
            <img
              src={order?.product[0]?.productData?.images[0] || dummyPdtImg}
              alt={`gefmart order item ${order?.product[0]?.productData?.name}`}
            />
          </div>
          <div className={styles.content}>
            <div
              className={`${styles.nameAndActionBtn} flex-col md:flex-row gap-1`}
            >
              <div className="flex gap-2">
                <div className="w-3/4 lg:w-full">
                  <h1 style={{ fontSize: "15px", fontWeight: "500" }}>
                    {order?.product[0]?.productData?.name}
                  </h1>
                  <p
                    className={`text-gray-500 text-wrap ${styles.description}`}
                  >
                    {order?.product[0]?.productData?.description}
                  </p>
                  <p className={styles.placedOnText}>
                    Placed on{" "}
                    {dayjs(order?.statuses[0]?.date).format(
                      "ddd, DD-MMM-YYYY hh:mm a"
                    )}
                  </p>
                  {order?.product?.map((product, index) => (
                    <div className={styles.mappedPdtContainer}>
                      <img
                        src={product?.productData?.images[0] || dummyPdtImg}
                        alt={`gefmart order item ${order?.product[0]?.productData?.name}`}
                      />
                      <div>
                        <p>QTY: {product?.total_quantity}</p>
                        <p>PRICE: {product?.rate}</p>
                      </div>
                    </div>
                  ))}
                  <p className={styles.totalPrice}>
                    TOTAL PRICE: {order?.total_amount}
                  </p>
                </div>
              </div>

              {/* <div className="p-2 justify-center flex flex-col items-center md:w-1/2">
                <div className="md:text-xl font-medium mb-2 text-[#C5BE1C]">
                  Deliver on 03:40PM JAN 29
                </div>
              </div> */}

              <div className={styles.bottomContent}>
                {order?.statuses?.length === 1 && (
                  <p
                    onClick={() => cancelOrder(order?._id)}
                    style={{ cursor: "pointer", marginRight: "20px" }}
                  >
                    Cancel Order?
                  </p>
                )}
                <p
                  className={`${
                    show && selectedIndex === index
                      ? `text-slate-400`
                      : `text-[#00BF63]`
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    setSelectedIndex(index);
                    if (show && selectedIndex === index) {
                      setShow(!show);
                    } else {
                      setShow(true);
                    }
                  }}
                >
                  {show && selectedIndex === index ? "Less" : "Show"}
                </p>
              </div>
            </div>

            {show && selectedIndex === index && (
              <div className="bg-[#E9ECEB] p-5">
                <TimeLine items={order?.statuses} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyOrders;
