import { SearchOutlined } from "@mui/icons-material";

import styles from "./search.module.css";

export default function Search({
  setSearchKeyword,
  searchKeyword,
  placeholder,
  style,
}) {
  return (
    <div className={styles.container} style={style}>
      <input
        type="text"
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        className={styles.input}
        placeholder={`search ${placeholder}`}
      />
      <SearchOutlined className={styles.searchIcon} />
    </div>
  );
}
