import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { ImCross } from "react-icons/im";

import { NavbarDefault } from "../../Components/Home/Navbar";
import Item from "../../Components/Product/Item";
import { searchProduct } from "../../apis/products";
import Footer from "../../Components/Home/Footer";
import ShowLatest from "../../Components/Product/ShowLatest";
import styles from "./products.module.css";
import PaginationBtns from "../../Components/paginationBtns";
import { UserStates } from "../../context/UserContext";
import { getAllMarketPlaceListings } from "../../apis/constants";
import axios from "axios";

function Product() {
  const params = useLocation();

  const { currentPage, accessToken, setCurrentPage } = useContext(UserStates);

  const [total, setTotal] = useState();
  const [pages, setPages] = useState();
  const [latest, setLatest] = useState([]);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [sorted, setSorted] = useState({
    sorted: false,
    method: "low",
    firstTime: true,
    state: "",
  });

  // to get the searched product
  useEffect(() => {
    const fetchData = async () => {
      let data;
      if (params.state !== sorted.state) {
        data = await searchProduct(params.state, 1);
        setPagination(1);
        data && setLatest(data.data.data.slice(0, 5));
        setSorted((prev) => ({
          ...prev,
          firstTime: true,
          state: params.state,
        }));
      } else {
        if (sorted.sorted) {
          data = await searchProduct(params.state, pagination, sorted.method);
        } else {
          data = await searchProduct(params.state, pagination);
        }
      }

      if (data) {
        setTotal(data.data.totalPages);
        setProducts(data.data.data);
        setCurrentPage(Number(data.data.currentPage));
        setTotalPages(data.data.totalPages);
        setPages(data.data.totalPages * 20);
      } else {
        setProducts([]);
        setTotal(0);
        setLatest([]);
        setSorted((prev) => ({ ...prev, firstTime: true }));
      }
    };
    fetchData();
  }, [pagination, params.state, sorted.method]);

  //to show the poroducts according to the number
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (total) {
  //       const data = await searchProduct(params.state, currentPage);
  //       data && setPages((total - 1) * 20 + data.data.data.length);
  //     }
  //   };
  //   fetchData();
  // }, [total]);

  const SortByPrice = async (sort) => {
    if (sort) {
      setSorted((prev) => ({ ...prev, method: "high", sorted: true }));
      setPagination(1);
    } else {
      setSorted((prev) => ({ ...prev, method: "low", sorted: true }));
      setPagination(1);
    }
  };

  //setpagination
  const selectNumberOfResult = async (e) => {
    const selectedValue = parseInt(e.target.value);
    let paginationNumber = Math.floor(selectedValue / 20);
    setPagination(paginationNumber);
  };

  //to handlePagination
  const handlePagination = async (type) => {
    if (type === "previous" && pagination > 1) {
      setPagination((prev) => prev - 1);
    } else if (type === "next" && pagination < total) {
      setPagination((prev) => prev + 1);
    }
  };

  console.log("currentPage", currentPage);

  const getBrandWiseItems = (page) => {
    console.log("calling market place api from brand with page", page);
    const config = {
      method: "post",
      url: `${getAllMarketPlaceListings}/${page ? page : 1}`,
      data: { search: params?.state },
      headers: { Authorization: accessToken },
    };

    axios(config)
      .then((res) => {
        setTotal(res.data.totalPages);
        setProducts(res.data.data);
        setCurrentPage(Number(res.data.currentPage));
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => setProducts([]));
  };

  // useEffect(() => {
  //   if (params.state !== "" || params.state !== null) {
  //     setSearch(params.state);
  //   }
  //   // params.state &&
  //   //   params.state.charAt(0).toUpperCase() +
  //   //     params.state.slice(1).toLowerCase();
  // }, [params]);

  return (
    <div className="w-screen">
      <div>
        <div
          className={`poppins-medium md:overflow-hidden  ${
            openModal && "fixed"
          }`}
        >
          <div className="w-screen shadow-lg">
            <NavbarDefault />
          </div>

          <div className=" px-[10px] md:px-[30px] xm:px-[70px] flex ">
            <div className="mt-3 lg:w-3/4">
              <div
                className={`flex items-center justify-between ${styles.headContainer}`}
              >
                <p className="text-[18px] poppins-medium text-[#252525]">
                  Showing{" "}
                  <span
                    className="text-[14px] text-[#013220] rounded-md bg-[#E1E6E4]"
                    style={{
                      marginRight: "6px",
                      padding: "3px 1px 3px 5px",
                      color: "white",
                    }}
                  >
                    {pages ? pages : 0}{" "}
                  </span>{" "}
                  results for{" "}
                  <span className="text-[#00BF63] font-medium text-l">
                    {params.state
                      ? params.state.charAt(0).toUpperCase() +
                        params.state.slice(1).toLowerCase()
                      : "All Products"}
                  </span>
                </p>
                {/* <Search placeholder={"products"} /> */}
                {/* <div className="flex text-[#777777] text-[15px] items-center">
                  <p
                    className=" hidden md:block mx-5 cursor-pointer"
                    onClick={() => SortByPrice(false)}
                  >
                    Price : Low-high
                  </p>
                  <p
                    className=" hidden md:block cursor-pointer"
                    onClick={() => SortByPrice(true)}
                  >
                    Price : High-low
                  </p>
                  <button
                    className="p-1 bg-[#252525] text-white ml-5 rounded-md cursor-pointer hidden md:block lg:hidden"
                    onClick={() => setOpenModal(true)}
                  >
                    Latest
                  </button>
                </div> */}
              </div>
              <div className="mt-5">
                {products.map((item) => {
                  return (
                    <Link to={`/marketplace/${item._id}`}>
                      <Item product={item} />
                    </Link>
                  );
                })}
              </div>
              {/* {products.length > 0 && (
                <div className="flex md:flex-row flex-col md:gap-0 gap-3 justify-between my-3">
                  <div className="flex">
                    <p>Show </p>
                    <select
                      className="text-xs border-[#837272] border-2 mx-2 rounded-md focus-outline cursor-pointer"
                      onChange={selectNumberOfResult}
                    >
                      {pages &&
                        Array.from(
                          { length: Math.ceil(pages / 20) },
                          (_, index) => {
                            const value = (index + 1) * 20;
                            return (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            );
                          }
                        )}
                    </select>
                    <p>/ {pages} results</p>
                  </div>
                  <div className="flex">
                    <p
                      className="text-[#CCCCCC] poppins-medium text-[14px] items-center cursor-pointer"
                      onClick={() => handlePagination("previous")}
                    >
                      Previous
                    </p>
                    <div className="flex mx-3 items-center">
                      {Array.from(
                        { length: total },
                        (_, index) => index + 1
                      ).map((pageNumber) => (
                        <p
                          key={pageNumber}
                          className={
                            pageNumber === pagination
                              ? "text-[14px]  px-2 bg-[#013220] cursor-pointer text-white rounded-md"
                              : "  cursor-pointer text-[14px] mx-3"
                          }
                          onClick={() => setPagination(pageNumber)}
                        >
                          {pageNumber}
                        </p>
                      ))}
                    </div>
                    <p
                      className="text-[14px] underline cursor-pointer"
                      onClick={() => handlePagination("next")}
                    >
                      Next
                    </p>
                  </div>
                </div>
              )} */}
            </div>
            {products.length > 0 && (
              <div className="hidden lg:block w-1/4 ml-12 text-[#777777] mt-8">
                <p className="text-[20px] text-[#252525]">Latest</p>
                <ShowLatest latest={latest} />
              </div>
            )}
          </div>
        </div>
        {openModal && (
          <div className="absolute min-h-screen overflow-y-auto top-0 right-0 w-full flex justify-end bg-black bg-opacity-50 z-50">
            <div className="bg-white overflow-y-auto">
              <div className="flex justify-between p-2 items-center">
                <p className="text-[20px] text-[#252525]">Latest</p>
                <ImCross
                  className="font-normal"
                  onClick={() => setOpenModal(false)}
                />
              </div>
              <div className="px-6">
                <ShowLatest latest={latest} />
              </div>
            </div>
          </div>
        )}
        <PaginationBtns
          totalPages={totalPages}
          getItems={getBrandWiseItems}
          search={params?.state}
          style={{ position: "unset" }}
        />
        {!openModal && <Footer />}
      </div>
    </div>
  );
}

export default Product;
