import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BlogsNews from "../../Components/Home/BlogsNews";
import Brands from "../../Components/Home/Brands";
import Categories from "../../Components/Home/Categories";
import Footer from "../../Components/Home/Footer";
import Latest from "../../Components/Home/Latest";
import { NavbarDefault } from "../../Components/Home/Navbar";
import SearchBar from "../../Components/UI/SearchBar";
import MobileMenuBar from "../../Components/Home/MobileMenuBar";
import { getBanner } from "../../apis/Brands";
import { Link, useNavigate } from "react-router-dom";
import SliderComponent from "./Slider";

import styles from "./home.module.css";

const Home = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      async function fetch() {
        const response = await getBanner();
        setData(response.data.data);
      }
      fetch();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className=" relative min-h-screen overflow-hidden">
      <div className="w-screen shadow-lg">
        <NavbarDefault />
      </div>

      <div className={styles.quickLinks}>
        <Link to={`/about`}>About</Link>
        <Link to={`/warehouse`}>Gefmart Warehouse</Link>
        <Link to="/categories">Categories</Link>
        <Link to="/brands">Top Brands</Link>
        {/* <Link to="login">Login/Register</Link> */}
        <Link to="/marketplace/add">Sell on Gefmart</Link>
        {/* <li
            onClick={() => {
              Cookies.remove("gefmartUserToken");
              navigate("/login");
            }}
            style={{ cursor: "pointer" }}
          >
            Logout
          </li> */}
      </div>

      <div className=" w-screen mt-3">
        <SliderComponent slides={data} />

        <div className="lg:hidden mt-8">
          <SearchBar />
        </div>
        <div className="w-screen px-4 lg:px-12 py-4 lg:py-6 flex flex-col gap-4">
          <Categories />
          <Brands />
          <Latest />
        </div>
        <BlogsNews />
      </div>
      <MobileMenuBar />
      <Footer />
    </div>
  );
};

export default Home;
