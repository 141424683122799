import Api from "../utils/Api";
import products from "../utils/endpoints/product";

export const searchProduct = async (search, number, sort) => {
  try {
    let res;
    if (sort) {
      res = await Api.post(products.searchProduct(number), { search, sort });
    } else {
      res = await Api.post(products.searchProduct(number), { search });
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getProductById = async (id) => {
  try {
    let response = await Api.get(products.getProductById(id));
    return response;
  } catch (error) {
    console.log(error);
  }
};
